import { APIFilter, API_FILTER_OPERATORS } from '@/utils/api'

export default {
  async selectParteTrabajoTecnico (Vue, search, filter, sorter, page, idordenTrabajo) {
    const apiFilter = new APIFilter()
    apiFilter.addExact('idorden_trabajo', idordenTrabajo)
    if (search) {
      const apiFilterSearch = new APIFilter()
      apiFilterSearch
        .setOperator(API_FILTER_OPERATORS.OR)
        .addILike('codigo', search)
        .addILike('nombre_y_apellido', search)
      apiFilter.addNestedFilter(apiFilterSearch)
    }
    if (filter.idparteTrabajo.value) {
      apiFilter.addExact(filter.idparteTrabajo.field, filter.idparteTrabajo.value)
    }
    const resp = await Vue.$api.call('parteTrabajoTecnico.select',{
      page,
      filter: apiFilter,
      sorter,
    })
    return [resp.data.result.dataset, resp.data.result.metadata]
  },
  async selectParteTrabajoTecnicoRows (Vue, pks) {
    const apiFilter = new APIFilter()
    apiFilter
      .setOperator(API_FILTER_OPERATORS.AND)
      .addIn('idparte_trabajo_tecnico', pks)
    const resp = await Vue.$api.call('parteTrabajoTecnico.select', { filter: apiFilter })
    return [resp.data.result.dataset, resp.data.result.metadata]
  },
  async deleteParteTrabajoTecnico (Vue, idparteTrabajoTecnico) {
    await Vue.$api.call('parteTrabajoTecnico.delete', { idparte_trabajo_tecnico: idparteTrabajoTecnico })
  },
}
